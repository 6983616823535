import React from 'react'

import {
  Text,
  Flex,
  Box,
  Image
} from 'rebass'

import Layout from '../components/layout'
import Container from '../components/container'
import BottomHero from '../components/bottomHero'

import lynneHarris from '../images/dr_lynne_harris.jpg'
import dariana from '../images/dariana.jpg'
import cale from '../images/cale.jpg'

const AboutPage = () => (
  <Layout>
    <Container>
      {/* Hero */}
      <Flex flexWrap='wrap' py={3}>
        <Box mx='auto'>
          <Text textAlign='center' py={2} fontWeight='bold' fontSize={5}>About Reversity</Text>
        </Box>
      </Flex>

      <Flex flexWrap='wrap' alignItems='baseline' p={3}>
        <Box width={[1, 1/5]} px={2}>
          <Text fontSize={4} fontWeight='bold'>Our story</Text>
        </Box>
        <Box px={2} width={[1, 4/5]}>
          <Box py={2}>
            <Text fontSize={3} py={1}>
              Reversity was founded on the conviction that adult-onset diabetes and obesity are reversible conditions. Type 2 diabetes was largely unheard of 100 years ago. We see it as a product of our high-carb, sugar-rich, food environment and decades of misguided nutritional recommendations.
            </Text>
            <Text fontSize={3} py={1}>
              At the doctor's office, most people with diabetes are managed with a combination pills and insulin. Diabetes medications, however, do not treat the underlying insulin resistance, which then continues to progress over time. We offer a radical alternative that you will not hear at most doctors' offices: true diabetes reversal through diet and lifestyle change. Our emphasis is on diet quality rather than quantity; you will NOT be counting calories at Reversity!
            </Text>
            <Text fontSize={3} py={1}>
              How do we reverse diabetes? For most people struggling with excess weight, prediabetes, or diabetes, we recommend a low-carbohydrate, high-fat (LCHF) or ketogenic (keto) diet. This way of eating promotes rapid, sustainable, weight loss and restores insulin sensitivity. Every person is unique; we will work with you to determine the macronutrient ratio that is ideal for your personal physiology and health goals.
            </Text>
            <Text fontSize={3} py={1}>
              Is a high-fat diet healthy? Yes! Decades of studies have proven that low-fat, high-carb diets are ineffective for weight loss, and in fact promote the development of obesity and diabetes. But not all fats are created equal. At Reversity, we focus on minimizing your omega-6:omega-3 ratio to increase your HDL levels, reduce inflammation, and lower your the risk of heart disease.
            </Text>
            <Text fontSize={3} py={1}>
              No two people are alike. Similarly, no two clients at Reversity will follow the same plan to reverse their disease. Through intensive one-on-one consultation with Dr. Harris and our team of experts, your Reversity Plan will be formulated to achieve your personal health goals. Whether you tend to cook from scratch, eat out, or only have time for the drive thru, we can provide a concrete roadmap to help you succeed without changing your food budget or daily routines.
            </Text>
            <Text fontSize={3} py={1}>
              We do not offer or promote prepackaged meals, dietary supplements, or medications. If you are currently under the care of a physician for diabetes or prediabetes, it is important that you discuss all dietary changes with your physician.
            </Text>
            <Text fontSize={3} py={1}>
              Do you take insulin? Most insulin-dependent individuals following our recommendations will need to gradually decrease, and in many cases discontinue, their use of insulin. You will simply not need it as your carbohydrate intake decreases. In a recent study, 94% of individuals on a low-carbohydrate diet reduced or eliminated their need for insulin.
            </Text>
            <Text fontSize={3} py={1}>
              Reversity is a culmination of a decade of critical research into the science of diabetes reversal and weight loss, and a radical departure from a doctor's visit and a prescription. If you are ready to be healthy again without medications, this is the plan for you. We are excited to invite you to join us!
            </Text>
          </Box>
        </Box>
      </Flex>
      <Flex flexWrap='wrap' alignItems='flex-start' p={3}>
        <Box width={[1, 1/5]} p={2}>
          <Text fontSize={4} fontWeight='bold'>Our founder</Text>
        </Box>
        <Box px={2} width={[1, 4/5]}>
          <Box py={2}>
            <Image src={lynneHarris} py={1} width={[1, 1/2]} alt='Dr. Lynne Harris' />
            <Box py={2}>
              <Text fontSize={3} py={1}>
                Dr. Lynne Harris, MS, PhD, received her master's degree from the University of Washington School of Medicine and her doctorate from the University of Washington School of Public Health. She has been studying diabetes management and obesity for 15 years, and has been widely published by the <Text style={{ fontStyle: 'italic', display: 'inline-block' }}>MIT Press</Text> and leading peer-reviewed journals such as <Text style={{ fontStyle: 'italic', display: 'inline-block' }}>Diabetes Care</Text> and <Text style={{ fontStyle: 'italic', display: 'inline-block' }}>Medical Care</Text>. Her research at the University of Washington has focused on transforming the management of diabetes by connecting patients and health care providers through technology.
              </Text>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Flex flexWrap='wrap' alignItems='flex-start' p={3}>
        <Box width={[1, 1/5]} p={2}>
          <Text fontSize={4} fontWeight='bold'>Our team</Text>
        </Box>
        <Box px={2} width={[1, 4/5]}>
          <Flex alignItems='baseline' flexWrap='wrap' mx={-2}>
            <Box width={[1, 1/2]} px={2}>
              <Image src={dariana} py={1} width={[1/2, 1/4]} alt='Dariana Valcarcel' />
              <Box py={2}>
                <Text color='gray' fontWeight='bold' fontSize={1}>
                  Dariana Valcarcel, Operations
                </Text>
                <Text fontSize={3} py={3}>
                  Dariana holds a bachelor's degree in Health Services Administration and a master's degree in Psychology. She lives in sunny California with her husband, two children, and pet pig.
                </Text>
              </Box>
            </Box>
            <Box width={[1, 1/2]} px={2}>
              <Image src={cale} py={1} width={[1/2, 1/4]} alt='Cale Sennett' />
              <Box py={2}>
                <Text color='gray' fontWeight='bold' fontSize={1}>
                  Cale Sennett, Web Developer
                </Text>
                <Text fontSize={3} py={3}>
                  Cale holds a bachelor's degree in Computer Science from Illinois Wesleyan University. He has worked for Shopify and founded a career exploration platform for K-12 students. Cale lives in Seattle and enjoys baking sourdough, taking photographs, and writing and playing music.
                </Text>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Container>
    <BottomHero bg='lightGray' />
  </Layout>
)

export default AboutPage
